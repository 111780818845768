// src/components/EmailDetail.js
import React, { useRef, useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useParams, useNavigate } from "react-router-dom";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactToPrint from 'react-to-print';
import { ENDPOINT } from '../config';
import EmailUpdateModal from './EmailUpdateModal';

function removeHtmlTags(content) {
  const tagPattern = /<\/?[^>]+>/gi;
  return content?.replace(tagPattern, '');
}

function highlightWords(htmlString, words, type) {
  console.log('htmlString', words);
  if (!htmlString || !words || words.length === 0 || words[0] === '' || type === 'title') {
    return htmlString; // Return original string if no words to highlight
  }

  // Escape special characters for the words to safely use in regex
  const escapedWords = words.map(word => word.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&'));

  // Create a regex pattern to match any part of the words
  const regex = new RegExp(`(${escapedWords.join('|')})`, 'gi');

  // Replace matched words with highlighted versions
  return htmlString.replace(regex, match => `<span style="background-color: green;">${match}</span>`);
}

function EmailDetail({ email_data, searchWords, searchType }) {
  const [email, setEmail] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editedContent, setEditedContent] = useState('');
  const componentRef = useRef();
  const { id } = useParams();

  useEffect(() => {
    if (!email_data) {
      fetchEmail();
      
    } else {
      setEmail(email_data);
      setEditedContent(email_data?.body || '');
    }
  }, [email_data]);

  const fetchEmail = async () => {
    console.log('id', id);
    const response = await fetch(`${ENDPOINT}/emails/get-email/${id}`);
    const data = await response.json();
    setEmail(data?.data);
    setEditedContent(data?.data?.body || '');
  };

  const handleEditClick = () => {
    console.log("handleEditClick", email?.body, isModalOpen)
    setIsModalOpen(true);
  };

  const handleUpdate = async () => {
    try {
      const response = await fetch(`${ENDPOINT}/emails/update-email/${id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ body: editedContent }),
      });
      if (response.ok) {
        toast.success("Email updated successfully!", {
          position: "bottom-left",
          autoClose: 2000
        });
        fetchEmail()
        setIsModalOpen(false);
      } else {
        console.error("Error updating email:", response.statusText);
      }
    } catch (error) {
      console.error("Error updating email:", error);
    }
  };

  function getFirstHashString(input) {
    if (!input) {
      return "No Title Found";
    }
    const lines = input?.split('\n');
    for (let line of lines) {
      if (line.startsWith('# ')) {
        return line.slice(2)?.trim();
      }
    }
    return "No Title Found";
  }

  return (
    <div className="email-detail print-container" ref={componentRef}>
      {email ? (
        <>
          <div className="email-header">
            <h2>{email?.title}</h2>
            <div>
              <CopyToClipboard text={removeHtmlTags(email?.body)}
                onCopy={() => toast.success("Copied!", {
                  position: "bottom-left",
                  autoClose: 2000
                })}>
                <button className="tooltip-button">
                  <div className="fa fa-copy"></div>
                  <span className="tooltip-text">Copy</span>
                </button>
              </CopyToClipboard>
              <ReactToPrint
                trigger={() => <button className="tooltip-button">
                  <div className="fa fa-print"></div>
                  <span className="tooltip-text">Print</span>
                </button>}
                content={() => componentRef.current}
              />
              <EmailUpdateModal
                emailId={id}
                initialContent={editedContent}
                title={email?.title}
                onUpdate={fetchEmail}
              />
            </div>
          </div>
          <div dangerouslySetInnerHTML={{ __html: `<pre>${highlightWords(email?.content, searchWords, searchType)}</pre>` }} />
        </>
      ) : (
        <p style={{ margin: 'auto', textAlign: 'center' }}>Select an email to view details</p>
      )}
      <ToastContainer />
    </div>
  );
}

export default EmailDetail;

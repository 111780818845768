import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ENDPOINT } from '../config';

function EmailUpdateModal({ emailId, initialContent, title, onUpdate }) {
  
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editedContent, setEditedContent] = useState(initialContent);
  const [editedTitle, setEditedTitle] = useState(title);

  useEffect(() => {
    setEditedContent(initialContent);
    setEditedTitle(title);
  }, [initialContent, title]);

  const handleUpdate = async () => {
    try {
      const response = await fetch(`${ENDPOINT}/emails/update-email/${emailId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ title: editedTitle, body: editedContent }),
      });
      if (response.ok) {
        toast.success("Email updated successfully!", {
          position: "bottom-left",
          autoClose: 2000
        });
        onUpdate();
        setIsModalOpen(false);
      } else {
        console.error("Error updating email:", response.statusText);
      }
    } catch (error) {
      console.error("Error updating email:", error);
    }
  };

  return (
    <>
      <button className="tooltip-button" onClick={() => setIsModalOpen(true)}>
        <div className="fa fa-edit"></div>
        <span className="tooltip-text">Edit</span>
      </button>
      {isModalOpen && (
        <div className="modal-overlay" style={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          zIndex: 1000
        }}>
          <div className="modal-content" style={{
            backgroundColor: 'white',
            padding: '20px',
            borderRadius: '8px',
            width: '80%',
            maxWidth: '600px',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)'
          }}>
            <span className="close-button" onClick={() => setIsModalOpen(false)}>&times;</span>
            <h3>Edit Email</h3>
            <input
              type="text"
              value={editedTitle}
              onChange={(e) => setEditedTitle(e.target.value)}
              style={{ width: '100%', padding: '10px', borderRadius: '5px', border: '1px solid #ccc', marginBottom: '10px' }}
              placeholder="Edit Title"
            />
            <textarea
              value={editedContent}
              onChange={(e) => setEditedContent(e.target.value)}
              rows="10"
              style={{ width: '100%', padding: '10px', borderRadius: '5px', border: '1px solid #ccc' }}
            />
            <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '10px' }}>
              <button onClick={handleUpdate} style={{ marginRight: '10px', padding: '8px 16px', backgroundColor: '#4CAF50', color: 'white', border: 'none', borderRadius: '4px', cursor: 'pointer' }}>
                Update
              </button>
              <button onClick={() => setIsModalOpen(false)} style={{ padding: '8px 16px', backgroundColor: '#f44336', color: 'white', border: 'none', borderRadius: '4px', cursor: 'pointer' }}>
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default EmailUpdateModal; 